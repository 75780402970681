import { KnownError } from "..";
import { StackAssertionError, captureError, concatStacktraces } from "./errors";
import { DependenciesMap } from "./maps";
import { Result } from "./results";
import { generateUuid } from "./uuids";
export function createPromise(callback) {
    let status = "pending";
    let valueOrReason = undefined;
    let resolve = null;
    let reject = null;
    const promise = new Promise((res, rej) => {
        resolve = (value) => {
            if (status !== "pending")
                return;
            status = "fulfilled";
            valueOrReason = value;
            res(value);
        };
        reject = (reason) => {
            if (status !== "pending")
                return;
            status = "rejected";
            valueOrReason = reason;
            rej(reason);
        };
    });
    callback(resolve, reject);
    return Object.assign(promise, {
        status: status,
        ...status === "fulfilled" ? { value: valueOrReason } : {},
        ...status === "rejected" ? { reason: valueOrReason } : {},
    });
}
const resolvedCache = new DependenciesMap();
/**
 * Like Promise.resolve(...), but also adds the status and value properties for use with React's `use` hook, and caches
 * the value so that invoking `resolved` twice returns the same promise.
 */
export function resolved(value) {
    if (resolvedCache.has([value])) {
        return resolvedCache.get([value]);
    }
    const res = Object.assign(Promise.resolve(value), {
        status: "fulfilled",
        value,
    });
    resolvedCache.set([value], res);
    return res;
}
const rejectedCache = new DependenciesMap();
/**
 * Like Promise.reject(...), but also adds the status and value properties for use with React's `use` hook, and caches
 * the value so that invoking `rejected` twice returns the same promise.
 */
export function rejected(reason) {
    if (rejectedCache.has([reason])) {
        return rejectedCache.get([reason]);
    }
    const res = Object.assign(Promise.reject(reason), {
        status: "rejected",
        reason: reason,
    });
    rejectedCache.set([reason], res);
    return res;
}
const neverResolvePromise = pending(new Promise(() => { }));
export function neverResolve() {
    return neverResolvePromise;
}
export function pending(promise, options = {}) {
    const res = promise.then(value => {
        res.status = "fulfilled";
        res.value = value;
        return value;
    }, actualReason => {
        res.status = "rejected";
        res.reason = actualReason;
        throw actualReason;
    });
    res.status = "pending";
    return res;
}
/**
 * Should be used to wrap Promises that are not immediately awaited, so they don't throw an unhandled promise rejection
 * error.
 *
 * Vercel kills serverless functions on unhandled promise rejection errors, so this is important.
 */
export function ignoreUnhandledRejection(promise) {
    promise.catch(() => { });
    return promise;
}
export async function wait(ms) {
    if (!Number.isFinite(ms) || ms < 0) {
        throw new StackAssertionError(`wait() requires a non-negative integer number of milliseconds to wait. (found: ${ms}ms)`);
    }
    if (ms >= 2 ** 31) {
        throw new StackAssertionError("The maximum timeout for wait() is 2147483647ms (2**31 - 1). (found: ${ms}ms)");
    }
    return await new Promise(resolve => setTimeout(resolve, ms));
}
export async function waitUntil(date) {
    return await wait(date.getTime() - Date.now());
}
export function runAsynchronouslyWithAlert(...args) {
    return runAsynchronously(args[0], {
        ...args[1],
        onError: error => {
            if (error instanceof KnownError && process.env.NODE_ENV.includes("production")) {
                alert(error.message);
            }
            else {
                alert(`An unhandled error occurred. Please ${process.env.NODE_ENV === "development" ? `check the browser console for the full error.` : "report this to the developer."}\n\n${error}`);
            }
            args[1]?.onError?.(error);
        },
    }, ...args.slice(2));
}
export function runAsynchronously(promiseOrFunc, options = {}) {
    if (typeof promiseOrFunc === "function") {
        promiseOrFunc = promiseOrFunc();
    }
    const duringError = new Error();
    promiseOrFunc?.catch(error => {
        options.onError?.(error);
        const newError = new StackAssertionError("Uncaught error in asynchronous function: " + error.toString(), { cause: error });
        concatStacktraces(newError, duringError);
        if (!options.noErrorLogging) {
            captureError("runAsynchronously", newError);
        }
    });
}
class TimeoutError extends Error {
    constructor(ms) {
        super(`Timeout after ${ms}ms`);
        this.ms = ms;
        this.name = "TimeoutError";
    }
}
export async function timeout(promise, ms) {
    return await Promise.race([
        promise.then(value => Result.ok(value)),
        wait(ms).then(() => Result.error(new TimeoutError(ms))),
    ]);
}
export async function timeoutThrow(promise, ms) {
    return Result.orThrow(await timeout(promise, ms));
}
export function rateLimited(func, options) {
    let waitUntil = performance.now();
    let queue = [];
    let addedToQueueCallbacks = new Map;
    const next = async () => {
        while (true) {
            if (waitUntil > performance.now()) {
                await wait(Math.max(1, waitUntil - performance.now() + 1));
            }
            else if (queue.length === 0) {
                const uuid = generateUuid();
                await new Promise(resolve => {
                    addedToQueueCallbacks.set(uuid, resolve);
                });
                addedToQueueCallbacks.delete(uuid);
            }
            else {
                break;
            }
        }
        const nextFuncs = options.batchCalls ? queue.splice(0, queue.length) : [queue.shift()];
        const start = performance.now();
        const value = await Result.fromPromise(func());
        const end = performance.now();
        waitUntil = Math.max(waitUntil, start + (options.throttleMs ?? 0), end + (options.gapMs ?? 0));
        for (const nextFunc of nextFuncs) {
            if (value.status === "ok") {
                nextFunc[0](value.data);
            }
            else {
                nextFunc[1](value.error);
            }
        }
    };
    runAsynchronously(async () => {
        while (true) {
            await next();
        }
    });
    return () => {
        return new Promise((resolve, reject) => {
            waitUntil = Math.max(waitUntil, performance.now() + (options.debounceMs ?? 0));
            queue.push([resolve, reject]);
            addedToQueueCallbacks.forEach(cb => cb());
        });
    };
}
export function throttled(func, delayMs) {
    let timeout = null;
    let nextAvailable = null;
    return async (...args) => {
        while (nextAvailable !== null) {
            await nextAvailable;
        }
        nextAvailable = new Promise(resolve => {
            timeout = setTimeout(() => {
                nextAvailable = null;
                resolve(func(...args));
            }, delayMs);
        });
        return await nextAvailable;
    };
}
